<template>
  <v-container fluid>
    <v-layout column sheet>
      <DataTable ref="contractReservationsTable" v-bind.sync="tableProps">
        <template #actionDropDown>
          <v-menu v-if="!multiselectActive">
            <template #activator="{ on }">
              <v-btn
                :id="`contract-reservations-list-button-actions`"
                class="btn-secondaryaction"
                v-on="on"
              >
                Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                :id="`contract-reservations-offer-all-referrals`"
                @click="offerAllReferrals"
              >
                <v-list-tile-title>Offer All Referrals</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`contract-reservations-send-driver-info`"
                @click.stop="sendAllDriverInfo"
              >
                <v-list-tile-title>Send Driver Info</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </template>
        <template #multiSelectActions>
          <ContractReservationListMultiselectActions
            v-if="multiselectActive"
            :contract="contract"
            :selected-rows="tableProps.selectedRows"
            @unselect-row="unselectRow"
          />
        </template>
      </DataTable>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import DataTable from '@/components/DataTable.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import ReservationTableDetail from '@/components/ReservationTableDetail.vue'
import brokerActionsWrapper from '@/components/BrokerAndAdminActionsWrapper'
import ReservationTableActions from '@/components/ReservationTableActions.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import ContractReservationListMultiselectActions from '@/components/ContractReservationListMultiselectActions.vue'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import { EventBus } from '@/utils/event-bus'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  textLike,
} from '@/utils/predefined'

const reservationStatusMap = [
  { text: 'Upcoming', value: 'upcoming' },
  { text: 'Started', value: 'started' },
  { text: 'Finished', value: 'finished' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Hold', value: 'hold' },
]
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]
const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]

const categories = [
  {
    _t_id: 'cfb42fbe',
    text: 'Reservation Status',
    prop: 'reservationStatus',
    values: deepClone(reservationStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Referral Status',
    prop: 'referralStatus',
    values: deepClone(referralStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'cc4277cd',
    text: 'Payment Status',
    prop: 'paymentStatus',
    values: deepClone(paymentStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'b1540be4',
    text: 'Referral Payment Status',
    prop: 'referralPaymentStatus',
    values: deepClone(paymentStatusMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    DataTable,
    ContractReservationListMultiselectActions,
  },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        initialFilters: [],
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        total: 0,
        tableId: 'customer_details_reservation_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detailKeyId: 'reservationId',
        isDetailed: true,
        detail: ReservationTableDetail,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        categories,
        enableSelectableRows: true,
        enableSelectAll: true,
        selectedRows: [],
      },
      reservationList: {},
    }
  },
  computed: {
    ...authComputed,
    multiselectActive() {
      return this.$refs.contractReservationsTable?.showMultiSelectActions
    },
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group?.key === 'admin'
    this.tableProps.enableExport = this.canExport()
    await this.getColumns()
    if (this.$route.query.reservationId) {
      const reservationIdFilter = {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'd7df53d5'
        ),
        value: this.$route.query.reservationId,
      }
      this.tableProps.initialFilters = [reservationIdFilter]
    }
    this.tableProps.initialFilters.push({
      column: this.tableProps.columns.find(
        (column) => column._t_id === 'contract_id_filter_1'
      ),
      value: this.contract.contractId,
      hideOnFilterBar: true,
    })

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
  },
  methods: {
    async getFullReservationList() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: -1,
        page: this.currentPage,
        reservations: 'company',
      }

      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTableView',
        params
      )
      this.reservationList = reservationData?.data?.resultList
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getColumns() {
      const columnsOut = []
      const brokerWrappedActions = await brokerActionsWrapper(
        ReservationTableActions,
        this.currentUser
      )
      columnsOut.push(
        {
          _t_id: '089d3f8b',
          prop: '/',
          text: 'Actions',
          component: brokerWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          isContract: true,
          type: 'actions',
        },
        {
          _t_id: 'd7df53d5',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'managedId',
          defaultSort: true,
        },
        {
          _t_id: '94e2fbdf',
          prop: 'tripRouteName',
          text: 'Trip Name',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          filterProp: 'trip.routeName',
          sortProp: 'trip.routeName',
        }
      )

      // Standard columns
      columnsOut.push(
        {
          _t_id: 'aee98743',
          prop: 'startDate',
          text: 'Date',
          computedText: (item) => DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: 'ad281940',
          prop: 'startDate',
          text: 'Time',
          computedText: (item) => DateTime.fromISO(item).toFormat('t ZZZZ'),
          sort: true,
          filter: true,
          type: 'time',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '1791630b',
          prop: 'referredTo/name',
          text: 'Referred To',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          list: 'referredTo',
          listProp: 'name',
          component: ReservationActionableColumn,
          displayType: 'list',
          sortProp: 'referrals.company.name',
          filterProp: 'referrals.company.name',
          filterType: 'contains',
          childMethod: 'or',
          predefined: textLike,
        },
        {
          _t_id: 'f72a351c',
          prop: 'passengerCount',
          text: 'Passengers',
          sort: true,
          filter: false,
          type: 'number',
          sortProp: 'passengerCount',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '5dbd1813',
          prop: 'requiredVehiclesCount',
          text: 'Vehicles',
          sort: true,
          filter: false,
          type: 'number',
          sortProp: 'requiredVehiclesCount',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'contract_id_filter_1',
          filterType: 'eq',
          prop: 'contractId',
          filterProp: 'trip.quote.contractId',
          text: 'Customer Id',
          type: 'number',
          childMethod: 'and',
          filter: true,
          filterPlaceholder: true,
        }
      )

      columnsOut.push(
        {
          _t_id: '436c54d5',
          prop: 'assignedDriverPercentage',
          text: 'Driver',
          computedText: this.assignedDriverComputedText,
          sort: false,
          filter: false,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        },
        {
          _t_id: '8de7075a',
          prop: 'assignedVehiclePercentage',
          text: 'Vehicle',
          computedText: this.assignedVehicleComputedText,
          sort: false,
          filter: false,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        }
      )

      columnsOut.push(
        {
          _t_id: 'dbdd5d01',
          prop: 'reservationStatusLabel',
          text: 'Status',
          sort: true,
          filter: false,
          type: 'text',
          sortProp: 'reservationStatusLabel',
        },
        {
          _t_id: '2047994a',
          prop: 'customerTotal',
          text: 'Customer Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        }
      )

      columnsOut.push(
        {
          _t_id: '37a7630b',
          prop: 'openForBid',
          text: 'Open For Bid',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          sortProp: 'trip.openForBid',
          filterProp: 'trip.openForBid',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Open' : 'Closed'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Open' : 'Closed'
          },
          predefined: [
            {
              text: 'Open For Bid',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              checkbox: true,
              value: 1,
              customEval: (activeFilter) => {
                if (!activeFilter.value) {
                  activeFilter.value = true
                } else {
                  activeFilter.value = undefined
                }
              },
            },
          ],
        },
        {
          _t_id: '712a430a',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: false,
          type: 'text',
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'xb7bcc554',
          prop: 'isCharterUpQuote',
          sortProp: 'trip.quote.isCharterUpQuote',
          filterProp: 'trip.quote.isCharterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Marketplace',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              checkbox: true,
              value: 1,
              customEval: (activeFilter) => {
                if (!activeFilter.value) {
                  activeFilter.value = true
                } else {
                  activeFilter.value = undefined
                }
              },
            },
          ],
        }
      )

      columnsOut.push(
        // more standard columns
        {
          _t_id: '15fb5cf1',
          prop: 'quoteId',
          text: 'Quote ID',
          sort: true,
          filter: true,
          type: 'number',
          method: 'and',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'a9818d8d',
          prop: 'dueDate',
          sortProp: 'trip.dueDate',
          filterProp: 'trip.dueDate',
          text: 'Due Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'e53ec354',
          prop: 'tripTypeLabel',
          sortProp: 'trip.tripType.label',
          filterProp: 'trip.tripType.label',
          text: 'Trip Type',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '1cd7a99f',
          prop: 'referralAmount',
          text: 'Referral Total',
          computedText: this.referralTotalComputedText,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          sortProp: 'referralAmount',
          filterProp: 'referralAmount',
          filterType: 'contains',
        },
        {
          _t_id: '63578070',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '5a4b6e86',
          prop: 'reservationStatus',
          text: 'Reservation Status',
          computedText: (item) =>
            this.mapCategoryText(reservationStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'ab65abb5',
          prop: 'paymentStatus',
          text: 'Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'c8da5438',
          prop: 'referralPaymentStatus',
          text: 'Referral Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '32920d6e',
          prop: 'firstStopAddress',
          text: 'Pickup Location',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          childMethod: 'or',
          unset: ['6b01a5c0', 'c22dbed8'],
          predefined: [
            {
              text: 'Radius',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '9522531a',
                  text: 'Location Radius',
                  filterType: 'radius',
                  component: PickupRadiusFilter,
                },
              ],
            },
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                },
              ],
            },
          ],
        },
        {
          _t_id: '17d71ae2',
          prop: 'balance',
          text: 'Balance',
          computedText: (item) => currencyFilter(item),
          component: ReservationActionableColumn,
          displayType: 'action-item',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '3b627c5d',
          prop: 'distance',
          filterProp: 'trip.distance',
          sortProp: 'trip.distance',
          text: 'Distance',
          computedText: (item) => metersToMilesString(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '52a296fe',
          prop: 'drivingTime',
          filterProp: 'trip.drivingTime',
          sortProp: 'trip.drivingTime',
          text: 'Driving Time',
          computedText: (item) =>
            `${Number(
              Duration.fromObject({ seconds: item }.as('hours'))
            ).toFixed(2)} Hrs`,
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'e9bbe02f',
          prop: 'firstSentDate',
          text: 'Referred Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'adcdd544',
          prop: 'lastDropoffDate',
          text: 'Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '11cd6d05',
          prop: 'cancelledOn',
          text: 'Cancelled Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '4464729b',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '6b01a5c0',
          prop: 'distanceFromMarket',
          filterType: '',
          filterPlaceholder: true,
        },
        {
          _t_id: 'c22dbed8',
          prop: 'marketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '1eb6452a',
          prop: 'nearestMarketId',
          filterType: 'eq',
          filterPlaceholder: true,
        }
      )

      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTableView',
        params
      )
      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.selectedRows = []
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.fromJSDate(Date.now()) > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, referralAmount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(referralAmount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, referralAmount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    assignedDriverComputedText(row, column, action) {
      const { assignedDriverPercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedDriverPercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'drivers'
      }

      function getIconColor() {
        return assignedDriverPercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    assignedVehicleComputedText(row, column, action) {
      const { assignedVehiclePercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedVehiclePercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'vehicles'
      }

      function getIconColor() {
        return assignedVehiclePercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML,
        icon: getIconValue,
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) => r.roleName === 'can_export_reservations'
      )
      return !!canExportRole
    },
    unselectRow(item) {
      const reservationId = item.reservationId
      const index = this.tableProps.selectedRows.find(
        (r) => r.item?.reservationId === reservationId
      ).index
      this.tableProps.selectedRows.splice(index, 1)
    },
    async sendAllDriverInfo() {
      await this.getFullReservationList()
      this.$store.dispatch('app/openDialog', {
        component: () => import('./ReservationSendDriverInfo.vue'),
        data: {
          reservationList: this.reservationList,
        },
      })
    },
    async offerAllReferrals() {
      await this.getFullReservationList()
      this.$store.dispatch('app/openSidebarDialog', {
        component: () => import('./ContractReservationQuickRefer.vue'),
        data: {
          contract: this.contract,
          mode: 'enMasse',
          reservations: this.reservationList,
          title: 'Offer All Referrals',
        },
      })
    },
  },
}
</script>
